import jwt from 'jsonwebtoken'

const jitsiToken = (obj) => {
  return jwt.sign(obj, 'hasandigitalinovasi')
}

const emailForgotToken = (token) => {
  return jwt.verify(token, 'hasan-teknologi')
}

export {
  jitsiToken,
  emailForgotToken,
}
